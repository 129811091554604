
import MyTable from "@/components/MyTable/index.vue";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
} from "vue";
export default defineComponent({
  name: "",
  components: { MyTable },
  setup() {
    const data = reactive({
      Title: [
        { label: "工作时长", value: "user" },
        { label: "班次", value: "Account" },
        { label: "交班班组", value: "role" },
        { label: "交班组长", value: "type" },
        { label: "接班班组", value: "bz" },
        { label: "接班组长", value: "je" },
        { label: "生产产量", value: "can" },
        { label: "生产批次", value: "pi" },
      ],
      Arr: [
        {
          user: "2023-02-23 07:00 结束工作",
          Account: "早班",
          role: "抛丸一组",
          type: "赵二",
          bz: "抛丸二组",
          je: "李四",
          can: "2302件",
          pi: "202302200105~2023022",
        },
      ],
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
    });
    return {
      ...toRefs(data),
    };
  },
});
